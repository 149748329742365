import { AppBar, Box, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { changeAuthModalVisible } from '@/features/auth'
import { ROUTE_PATH } from '@/packages/paths'
import { I18n } from '@/services/i18n'
import { User } from '@/services/user'
import { Button } from '@/shared/components/button'
import { IconButton } from '@/shared/components/icon-button'
import { Text } from '@/shared/components/text'
import { Choose, Otherwise, When } from '@/shared/utils/choose'
import { useMedia } from '@/shared/utils/media'

import * as Styled from './styled'

export const Header = ({ user }: { user: User | null }) => {
  const navigate = useNavigate()
  const { isMobile } = useMedia()

  return (
    <Styled.Header>
      <AppBar component='nav'>
        <Box sx={{ mx: isMobile ? 1 : 5 }}>
          <Toolbar disableGutters sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Text id='features.promo.header.title' fontSize={18} component='div' color='white' />
            </Box>
            <Choose>
              <When condition={user}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Text sx={{ color: 'white', fontSize: 14 }}>{user?.username}</Text>
                  <IconButton
                    iconName='Login'
                    sx={{ fontSize: 20, color: 'white' }}
                    onClick={() => navigate(ROUTE_PATH.HOME)}
                  />
                </Box>
              </When>
              <Otherwise>
                <Button variant='contained' color='secondary' onClick={() => changeAuthModalVisible(true)}>
                  <I18n id='features.promo.header.button' />
                </Button>
              </Otherwise>
            </Choose>
          </Toolbar>
        </Box>
      </AppBar>
    </Styled.Header>
  )
}
