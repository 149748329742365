// ./main/index.tsx
import { Box, CircularProgress, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useState } from 'react'

import { NAV_CLASSES } from '@/shared/consts/onboarding'

import { $calendar, DayStatus, getMonthEntries, getMonthEntriesFx } from './model'
import { CalendarDay } from './ui/calendar-day'
import { EditEntryDialog } from './ui/edit-entry-modal'
import { MonthYearSwitcher } from './ui/month-year-switcher'

export const Calendar: React.FC = () => {
  const calendarCache = useStore($calendar)
  const isLoading = useStore(getMonthEntriesFx.pending)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth() + 1)

  const [selectedDay, setSelectedDay] = useState<DayStatus | null>(null) // Состояние для выбранного дня
  const [isDialogOpen, setDialogOpen] = useState(false) // Состояние для отображения модального окна

  // Ключ для кэша
  const cacheKey = `${year}-${month}`

  useEffect(() => {
    getMonthEntries({ year, month })
  }, [year, month])

  // Получаем данные из кэша
  const calendar = calendarCache[cacheKey]

  // Определяем первый день месяца
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay()
  const emptyDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1

  // Обработчик клика на день
  const handleDayClick = (day: DayStatus) => {
    setSelectedDay(day)
    setDialogOpen(true)
  }

  // Закрытие модального окна
  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedDay(null)
  }

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }} className={NAV_CLASSES.CALENDAR.PAGE}>
      <Typography variant='h4' sx={{ mb: 3, textAlign: 'center', color: 'text.primary' }}>
        Календарь
      </Typography>
      <MonthYearSwitcher year={year} month={month} onYearChange={setYear} onMonthChange={setMonth} />
      <Box
        display='grid'
        gridTemplateColumns='repeat(7, 1fr)'
        gap={1}
        sx={{ mb: 1, justifyItems: 'center', userSelect: 'none' }}
      >
        {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((day, index) => (
          <Typography key={index} variant='subtitle2' textAlign='center' color='text.primary'>
            {day}
          </Typography>
        ))}
      </Box>
      <Box
        display='grid'
        gridTemplateColumns='repeat(7, 1fr)'
        gap={1}
        sx={{
          justifyItems: 'center',
          '@media (max-width: 600px)': { gap: 0.5 }, // Адаптируем отступы на мобильных
          minHeight: 300, // Чтобы блок был виден при загрузке
          alignItems: 'center', // Центрируем спиннер
        }}
      >
        {isLoading ? (
          <CircularProgress sx={{ gridColumn: '1 / -1' }} /> // Показываем спиннер, занимающий всю ширину
        ) : (
          <>
            {Array.from({ length: emptyDays }).map((_, index) => (
              <Box key={`empty-${index}`} sx={{ width: 40, height: 40 }} />
            ))}
            {calendar?.map((day) => (
              <CalendarDay key={day.date} day={day} onClick={() => handleDayClick(day)} /> // Добавляем обработчик клика
            ))}
          </>
        )}
      </Box>
      {selectedDay && (
        <EditEntryDialog open={isDialogOpen} onClose={handleCloseDialog} day={selectedDay} /> // Модальное окно для редактирования
      )}
    </Box>
  )
}
