import { Box, Divider } from '@mui/material'
import { useStore } from 'effector-react'

import { ROUTE_PATH } from '@/packages/paths'
import { $user } from '@/services/user'
import { RouterLink } from '@/shared/components/router-link'
import { Text } from '@/shared/components/text'
import { useMedia } from '@/shared/utils/media'

import * as Styled from './styled'
import { BottomBlock } from './ui/bottom-block'
import { SidebarHelp } from './ui/help'
import { SidebarList } from './ui/list'
import { UserProfile } from './ui/user-profile'

export const SidebarMenu = () => {
  const user = useStore($user)
  const { isDesktop } = useMedia()

  if (!user) return null

  return (
    <Styled.SidebarContainer $isDesktop={isDesktop}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <RouterLink to={ROUTE_PATH.HOME}>
          <Box sx={{ width: '100%', textAlign: 'center', my: '16px', px: '4px' }}>
            <Text id='title' fontSize={18} lineHeight={1.1} component='div' color='text.primary' />
          </Box>
        </RouterLink>
        <Divider />
        <Box sx={{ px: 1, my: 1 }}>
          <UserProfile username={user.username} />
        </Box>
        <SidebarList />
      </Box>

      <Box sx={{ width: '100%' }}>
        <SidebarHelp onboardingStep={user.preferences.onboardingStep} />
        <Divider />
        <BottomBlock />
      </Box>
    </Styled.SidebarContainer>
  )
}
