import { useEffect, useState } from 'react'

/**
 * Хук для управления состоянием загрузки с минимальной длительностью отображения
 * @param isLoading Фактическое состояние загрузки
 * @param minDuration Минимальная длительность в миллисекундах
 * @returns Состояние отображения загрузки
 */
export const useLoadingWithMinDuration = (isLoading: boolean, minDuration: number = 1000): boolean => {
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    let timer: number

    if (isLoading) {
      // Если начинается загрузка, устанавливаем showLoading в true
      setShowLoading(true)
    } else if (showLoading && typeof window !== 'undefined') {
      // Если фактическая загрузка завершилась, ждем минимум заданное время перед отключением showLoading
      timer = window.setTimeout(() => {
        setShowLoading(false)
      }, minDuration)
    }

    // Очищаем таймер при изменении состояния или размонтировании компонента
    return () => clearTimeout(timer)
  }, [isLoading, minDuration, showLoading])

  return showLoading
}
