import { Box, styled } from '@mui/material'

export const Root = styled('div')<{ $backgroundUrl?: string }>(({ $backgroundUrl, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  background: $backgroundUrl ? `url(${$backgroundUrl}) 100% 100% no-repeat` : theme.palette.background.default,
  backgroundSize: $backgroundUrl ? 'cover' : 'auto',
}))

export const Container = styled(Box)({
  display: 'flex',
  flex: '1 0 auto',
})

export const Content = styled(Box)({
  padding: '8px 32px',
  flex: 1,
})
